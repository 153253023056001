import React from "react";
import styled from 'styled-components'

import PortableText from "./portableText";
import CTALink from "./CTALink";

import Intro from "./intro";


const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;


const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  .container {
    max-width: 500px;
  }

  @media (max-width: 900px) { 
    .container {
      padding-top: 25px;
      p, h1 {
        padding-left: 20px;
        padding-right: 20px;
      }

      h1 {
      font-size: 2.2rem;
    }

    p {
      font-size: 1.2rem;
    }


    button {
      font-size: 14px;
    }

    
  }
  }

`;


function Hero(props) {
  return (

    <Wrapper>
    <Intro />
    <Content>

    <div className="container ">
     
      <div className="flex flex-col w-full justify-center text-center md:text-center">
        <p className="uppercase tracking-loose w-full">{props.label}</p>
        <h1 className="my-4 text-5xl font-bold leading-tight">{props.heading}</h1>
        <div className="leading-normal text-2xl mb-8">
          <PortableText blocks={props.tagline} />
        </div>
        {props.cta && props.cta.title && (
          <CTALink
            {...props.cta}
            buttonActionClass="mx-auto  hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
          />
        )}
      </div>
    </div>
    </Content>
    
    </Wrapper>
  );
}

export default Hero;
