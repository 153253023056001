import React from "react";
import styled from "styled-components"
import { navigate } from 'gatsby-link'

const FormBox  = styled.div`
  width: 100%;
  padding: 25px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 20px;
  margin-bottom: 20px;
`
const FieldItem  = styled.div`
  @media (max-width: 900px) { 
    width: 100%;
  }
`

const FormTitle  = styled.h2`
  color: #000;
  font-size: 18px;
  padding-bottom: 15px;
  
`

const Layout = styled.div`
  width: 100%;
  display: flex;
  padding: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) { 
    margin-bottom: 50px;
  }
  


  h1 {
    font-size: 26px;
  }

  form {
    width: 100%;
    max-width: 600px;

    label {
      color: #000;
    }
    input, textarea {
      border: 1px solid rgba(0,0,0,0.2);
      width: 100%;
      color: #000;
      padding: 10px;
      background: transparent;
      margin-bottom: 15px;
    }

    [type="radio"] {
      width: 50px;

    }

  }
`

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function FormRegister() {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <Layout>
      <form
        name="register"
        method="post"
        action="/danke"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden><label>Don’t fill this out: <input name="bot-field" onChange={handleChange} /></label></p>

        <FormBox>
        <FormTitle>Meine Daten:</FormTitle>
        <br />
        <label>Anrede:<br /><input type="text" name="anrede" onChange={handleChange} /></label>
        <label>Nachname:<br /><input type="text" name="nachname" required onChange={handleChange} /></label>
        <label>Vorname:<br /><input type="text" name="vorname" required onChange={handleChange} /></label>
        <label>Geburtsdatum:<br /><input type="text" name="geburtsdatum" onChange={handleChange} /></label>
        <label>Postleitzahl:<br /><input type="text" name="postleitzahl" required onChange={handleChange} /></label>
        <label>Wohnort:<br /><input type="text" name="wohnort" required onChange={handleChange} /></label>
        <label>Straße:<br /><input type="text" name="straße" required onChange={handleChange} /></label>
        <label>Hausnummer:<br /><input type="text" name="hausnummer" required onChange={handleChange} /></label>
        <label>E-Mail:<br /><input type="email" name="email" required onChange={handleChange} /></label>
        </FormBox>
        
        <FormBox>
        <FormTitle>Mein Anteil:</FormTitle>
        <br />
        <fieldset>
        <FieldItem>
        <label>Einmalzahlung
        <input type="radio" name="zahlmethode" value="Einmalzahlung" required onChange={handleChange} /></label> 
        </FieldItem>
        <FieldItem>
        <label>Monatszahlung
        <input type="radio" name="zahlmethode" value="Monatszahlung" required onChange={handleChange} /></label> 
        </FieldItem>
        <br /><br />
        <label>Betrag:<br /><input type="text" name="betrag" required onChange={handleChange} /></label>
        </fieldset>
        </FormBox>

        <button type="submit" className="mx-auto ml-4 bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg">Absenden</button>

      </form>
    </Layout>
  )
}