import React from "react";

import FormRegister from "./form-register";

const RegisterComp = props => {

  return (
    <section className="bg-white border-b py-8">
      <FormRegister />
    </section>
  );
};

export default RegisterComp;
